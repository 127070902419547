import axios from "axios";

export async function saveConfiguration(apiUrl: string, requestBody: any, successCallBack?: () => void, errorCallback?: (error: any) => void) {
    try {
        const response = await axios.post(apiUrl, requestBody);
        console.log('Configuração salva com sucesso:', response);
        if (successCallBack) {
            successCallBack();
        }

        return response.data;

    } catch (error) {
        console.error('Erro ao salvar a configuração:', error);
        if (errorCallback) {
            errorCallback(error);
        }
        throw error;
    }
}

export async function deleteSingleEntity(apiUrl: string, id: number, successCallBack?: () => void, errorCallback?: (error: any) => void) {
    try {
        const response = await axios.delete(`${apiUrl}/${id}`);
        console.log('Entidade excluída com sucesso:', response);
        if (successCallBack) {
            successCallBack();
        }
        return response.data;

    } catch (error) {
        console.error('Erro ao excluir a entidade:', error);
        if (errorCallback) {
            errorCallback(error);
        }
        throw error;
    }
}
export async function deleteMultipleEntities(apiUrl: string, ids: number[], successCallBack?: () => void, errorCallback?: (error: any) => void) {
    try {
        const response = await axios.post(apiUrl, ids);
        console.log('Entidades excluídas com sucesso:', response);
        if (successCallBack) {
            successCallBack();
        }
        return response.data;

    } catch (error) {
        console.error('Erro ao excluir as entidades:', error);
        if (errorCallback) {
            errorCallback(error);
        }
        throw error;
    }
}

