import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import {BrokerProps} from "../../../Shared/Types/Broker";

const apiUrl = "api/broker";

const saveBroker = async (data: BrokerProps) => {
    try {
        return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const SaveBrokerReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveBroker(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Broker salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar broker: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchBroker = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const BrokerSearchReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["page broker", pagedSearchParams],
        queryFn: () => {
            return fetchBroker(pagedSearchParams);
        }
    });
};

const fetchAllPowerStation = async () => {
    try {
        return await axios
            .get(`api/power-station/find-all`,{timeout:30000})
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllPowerStationReactQuery = () => {
    return useQuery({
        queryKey: ["data-find-all-power-station"],
        queryFn: () => {
            return fetchAllPowerStation();
        }
    });
};

const fetchBrokerById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindBrokerByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchBrokerById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um broker: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteBroker = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const DeleteBrokerReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteBroker(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Broker excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]).then(r => r);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir broker: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchBrokersByPowerStationId = async (pagedSearchParams: PagedSearchParams, powerStationId: number) => {
    try {
        const urlParams = new URLSearchParams(pagedSearchParams.toURLSearchParams());
        if(powerStationId !== undefined){
            urlParams.append('powerStationId', powerStationId.toString());
            return await axios.get(`${apiUrl}/find-all-by-powerstation`,
                {
                    params: urlParams,
                    timeout:30000
                }
            ).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllBrokerByPowerStationIdReactQuery = (powerStationId: number | null, pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data-find-all-teleObjects-powerStations", powerStationId, pagedSearchParams],
        queryFn: () => {
            if (powerStationId !== null) {
                return fetchBrokersByPowerStationId(pagedSearchParams, powerStationId);
            }
        }
    });
};