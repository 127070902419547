import {
    Autocomplete,
    FilledTextFieldProps,
    OutlinedTextFieldProps,
    StandardTextFieldProps,
    TextField,
    TextFieldVariants
} from "@mui/material";
import {JSX} from "react/jsx-runtime";
import React, {useState} from "react";
import {RowTeleObjectData} from "./PowerStationFormEditTeleObjectPage";
import {Equipment, TeleObjectConfig} from "../../NotificationGroup/NotificationGroupFormPage";

interface Props {
    selectedTeleObject: RowTeleObjectData | null
    powerStationName: string | null
    checkedEquipmentEts: Equipment[];
    setSelectedTeleObject: any;
    setCheckedEquipmentEts: any;
    checkedTeleObjectsConfig: TeleObjectConfig[];
    setIsButtonDisabled: any;
    checkedEquipments: Equipment[];
    handleChangeEquipmentsCheckbox: any;
    handleChangeTeleObjectsConfigCheckbox: any;
}

export const PowerStationTeleObjectModal = ({selectedTeleObject, setSelectedTeleObject, powerStationName, checkedEquipmentEts, setCheckedEquipmentEts, checkedTeleObjectsConfig, setIsButtonDisabled, checkedEquipments, handleChangeEquipmentsCheckbox, handleChangeTeleObjectsConfigCheckbox}: Props) => {
    const handleChangeEquipmentsEtsCheckbox = (ets: number | undefined, index: number) => {
        let updatedEquipments = checkedEquipmentEts.map((equipment, i) => ({
            ...equipment,
            selected: i === index // Marca apenas o item clicado, desmarcando os demais
        }));

        let uptatedSelected = {
            ...selectedTeleObject,
            equipmentEts: ets
        }
        setSelectedTeleObject(uptatedSelected);
        setCheckedEquipmentEts(updatedEquipments);

    }

    const checkedEquipmentsSorted = checkedEquipments.sort((a, b) => a.name.localeCompare(b.name));
    const checkedTeleObjectsConfigSorted = checkedTeleObjectsConfig.sort((a, b) => a.name.localeCompare(b.name));

    const checkIfFieldsAreComplete = () => {
        const isComplete =
            !!selectedTeleObject?.equipmentEts &&
            !!selectedTeleObject?.equipmentName &&
            !!selectedTeleObject?.teleObjectConfigName;

        setIsButtonDisabled(!isComplete);
    };

    return (
        <>
            {/* Campo "Usina" desabilitado */}
            <TextField
                label="Usina"
                value={selectedTeleObject?.powerStationName ?? powerStationName}
                fullWidth
                margin="normal"
                disabled
                sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
            />

            {/* Select "ETS" */}
            <Autocomplete
                fullWidth
                sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                id="autocomplete-ets"
                options={checkedEquipmentEts} // Array com os itens de ETS
                getOptionLabel={(option) =>String(option.ets ?? '')} // Definindo qual campo exibir como label
                value={checkedEquipmentEts.find((item) => item.ets === selectedTeleObject?.equipmentEts) || null} // Definindo o valor selecionado
                onChange={(event, newValue) => {
                    if (newValue) {
                        handleChangeEquipmentsEtsCheckbox(newValue.ets, checkedEquipmentEts.indexOf(newValue)); // Chamando a função de mudança
                        setSelectedTeleObject((prev: any) => ({ ...prev, equipmentEts: newValue.ets }));
                        checkIfFieldsAreComplete();
                    }
                }}
                renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                    <TextField
                        {...params}
                        label="ETS"
                        variant="outlined"
                        placeholder="Selecione o ETS"
                    />
                )}
            />

            <Autocomplete
                fullWidth
                id="autocomplete-equipment"
                sx={{marginTop: 2, '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                options={checkedEquipmentsSorted} // Passa a lista de equipamentos como opções
                getOptionLabel={(option) => {
                    return option.name || "";
                }}
                value={
                    selectedTeleObject
                        ? checkedEquipmentsSorted.find(equipment =>
                        equipment.id === selectedTeleObject.equipmentId // Compara o ID do equipamento
                    ) || null
                        : null
                }
                onChange={(event, newValue) => {
                    // Atualiza o valor selecionado quando um item é escolhido
                    if (newValue) {
                        handleChangeEquipmentsCheckbox(newValue.name, checkedEquipmentsSorted.indexOf(newValue));
                        setSelectedTeleObject((prev: any) => ({ ...prev, equipmentName: newValue.name }));
                        checkIfFieldsAreComplete();
                    }
                }}
                renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                    <TextField
                        {...params}
                        label="Equipamento"
                        variant="outlined"
                        placeholder="Selecione o Equipamento"
                    />
                )}
            />

            <Autocomplete
                fullWidth
                sx={{marginTop: 2, '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                id="autocomplete-teleObjectConfig"
                options={checkedTeleObjectsConfigSorted}
                getOptionLabel={(option) =>String(option.name ?? '')}
                value={checkedTeleObjectsConfigSorted.find((item) => item.name === selectedTeleObject?.teleObjectConfigName) || null} // Definindo o valor selecionado
                onChange={(event, newValue) => {
                    if (newValue) {
                        handleChangeTeleObjectsConfigCheckbox(newValue.name, checkedTeleObjectsConfig.indexOf(newValue));
                        setSelectedTeleObject((prev: any) => ({ ...prev, teleObjectConfigName: newValue.name }));
                        checkIfFieldsAreComplete();
                    }
                }}
                renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                    <TextField
                        {...params}
                        label="Descrição do TO"
                        variant="outlined"
                        placeholder="Selecione o TO"
                    />
                )}
            />

            <TextField
                sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                label="Endereço do TO"
                value={selectedTeleObject?.teleObjectConfigPoint ?? ''}
                fullWidth
                margin="normal"
                disabled
            />
        </>
    )
}