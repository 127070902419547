import React, {FunctionComponent, MouseEvent, useCallback, useEffect, useRef, useState} from "react";

import {
    ADataGrid,
    ADataGridColumn,
    ADataGridFilter,
    PagedSearchParams,
} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../../../Utils/DataUitils";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {Alarm, clear, searchEntities} from "./AlarmSkidReducer";
import IconButton from "@mui/material/IconButton";
import {BorderColor, FactCheck} from "@mui/icons-material";
import {isArray} from "lodash";
import {DateFormat, LocalDateTimeFormatISO, validateDateFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Modal, useTheme} from "@mui/material";

import {usePowerStationNavigation} from "../../../../Shared/Hooks/usePowerStationNavigation";
import {useSkidNavigation} from "../../../../Shared/Hooks/useSkidNavigation";
import AlarmGroup from "./Components/AlarmGroup";
import {alarmSearchGroup, checkGroupingType, handleOnColor} from "../../../../Utils/AlarmsService";
import {useSnackbar} from "notistack";
import AlarmNotion from "../../Alarms/Components/AlarmNotion";
import {clearGroup} from "../../Alarms/Components/AlarmGroup/AlarmGroupReducer";
import {isValid} from "date-fns";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import {Storage} from "../../../../Utils/StorageUtils";
import {alarmAcknowledge, alarmFinalize, AlarmFinalizeArgs} from "../../Alarms/AlarmReducer";
import useInterval from "../../../../Shared/Hooks/useInterval";
import { hasPermission } from "../../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../../Config/Constants";

export const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'severityName',
        label: 'severidade',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'macroRegionName',
        label: 'macro',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'regionName',
        label: 'região',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'equipmentName',
        label: 'equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'dateTime',
        label: 'início',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTime",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'clearDate',
        label: 'finalizado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "clearDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'acknowledgementDate',
        label: 'reconhecido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "acknowledgementDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'userName',
        label: 'usuário',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
}
export interface AlarmRow {
    id: string;
    teleObjectId: number;
    severityId: number;
    severityName: string;
    severityColor: string;
    description: string;
    macroRegionName: string;
    regionName: string;
    powerStationId: number;
    powerStationName: string | JSX.Element;
    skidId: number;
    skidName: string | JSX.Element;
    equipmentId: number;
    equipmentName: string;
    clearDate?: Date | string;
    acknowledgementDate?: Date | string;
    dateTime: Date | string;
    userName: string;
    group: boolean | any;
    actionsConfig?: Array<ActionConfig>

}
const AlarmPage:FunctionComponent<{skidId:any}> = ({skidId}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const isLoading = useAppSelector((state : RootState) => state.alarmSkid.loading);
    const totalOfRecords = useAppSelector((state : RootState) => state.alarmSkid.totalOfRecords);
    const entities: Array<Alarm> = useAppSelector((state: RootState) => state.alarmSkid.entities);
    
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToAcknowledge = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DASHBOARD_POWER_STATION_ALARM_ACKNOWLEDGE
    ]);

    const isAuthorizedToFinalize = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DASHBOARD_POWER_STATION_ALARM_FINALIZE
    ]);

    const navigationToPowerStation = usePowerStationNavigation();
    const navigationToSkid = useSkidNavigation();

    const [rows, setRows] = useState<Array<AlarmRow>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const [open, setOpen] = React.useState(false);
    const [notionOpen, setNotionOpen] = React.useState(false);
    const [groupOpen, setGroupOpen] = React.useState(false);
    const [entityToModel, setEntityToModel] = useState<object>();
    const [selectedRows, setSelectedRows] = useState<any>();

    const AUTH_TOKEN_KEY = 'api-token-key';
    const token = Storage.local.get(AUTH_TOKEN_KEY);
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const username = tokenData.preferred_username;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);

    const handleOnRowOpen = useCallback(async (row: any) => {
        if(row && !isArray(row)){
            setGroupOpen(true);
            let params = new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100)
            params.filters.push( { name: 'skidId', condition: 'equals', value: row.skidId.toString(), sort: 'none' })
            params.filters.push(...checkGroupingType(row))
            try {
                const hasGroup = await alarmSearchGroup(params);
                if (!hasGroup) {
                    enqueueSnackbar("Sem dados agrupados!", {variant: 'warning'})
                } else {
                    setEntityToModel(row)
                    setOpen(true);
                }
            } catch (error) {
                console.error(error);
            }
        }else {
            setOpen(false);
        }
    }, []);
    const handleOnNotionOpen = useCallback(async (data: any) => {
        if(data && !isArray(data)) {
            setNotionOpen(true);
            setEntityToModel(data);
            setOpen(true);
        }
    }, []);

    // close modal
    const handleCloseModal = () => {
        dispatch(clearGroup)
        setOpen(false);
        setGroupOpen(false);
        setNotionOpen(false);
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        dispatch(clear());
        if (!isLoading) {
            pagedSearchParams.filters.push( { name: 'skidId', condition: 'equals', value: skidId, sort: 'none' })
            dispatch(searchEntities(pagedSearchParams));
        }
    }, [skidId])

    useInterval(() => {
        if (!isLoading) {
            pagedSearchParams.filters.push( { name: 'skidId', condition: 'equals', value: skidId, sort: 'none' })
            dispatch(searchEntities(pagedSearchParams));
        }
    }, 60000) // 5 minutes

    const finalizeAlarms = useCallback(async () => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmFinalize(params))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(searchEntities(pagedSearchParams))
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmFinalize({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(searchEntities(pagedSearchParams))
            }
        }
    },[selectedRows]);


    const alarmAcknowledges = useCallback(async() => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmAcknowledge(params))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clear());
                dispatch(searchEntities(pagedSearchParams))
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmAcknowledge({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clear());
                dispatch(searchEntities(pagedSearchParams))
            }
        }
    },[selectedRows]);

    const formatISO = (date: string) => {
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }
        if(isValid(new Date(date))){
            return LocalDateTimeFormatISO(date)
        }
        enqueueSnackbar("Data invalida!", {variant: 'error'})
        return "";
    }

    const paramsRef = useRef(false);
    useEffect(() => {
        if(paramsRef.current){
            if(pagedSearchParams.filters[6] && pagedSearchParams.filters[6].value.length > 0){
                pagedSearchParams.filters[6].value = formatISO(pagedSearchParams.filters[6].value);
            }

            if(pagedSearchParams.filters[7] && pagedSearchParams.filters[7].value.length > 0){
                pagedSearchParams.filters[7].value = formatISO(pagedSearchParams.filters[7].value);
            }

            if(pagedSearchParams.filters[8] && pagedSearchParams.filters[8].value.length > 0){
                pagedSearchParams.filters[8].value = formatISO(pagedSearchParams.filters[8].value);
            }
            pagedSearchParams.filters.push( { name: 'skidId', condition: 'equals', value: skidId, sort: 'none' })
                dispatch(searchEntities(pagedSearchParams));

        }else {
            paramsRef.current = true;
        }
    },[pagedSearchParams]);

    const handleNavigationPowerPlant = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStation(powerPlantId);
    }

    const handleNavigationSkid = (event: MouseEvent<HTMLElement>, powerStationId: number, skidId: number): void => {
        event.preventDefault();
        navigationToSkid(powerStationId, skidId);
    }


    const handleClickOpen = (rows:any) => {
        if(isAuthorizedToFinalize) {
            setSelectedRows(rows);
            setDialogOpen(true);
        } else {
            enqueueSnackbar("Você não está autorizado à acessar essa funcionalidade", {
                variant: 'warning',
            });
            return null;
        }
    };

    const handleAcknowledgeClick = (rows:any) => {
        if(isAuthorizedToAcknowledge) {
            setSelectedRows(rows);
            setAcknowledgeDialog(true)
        } else {
            enqueueSnackbar("Você não está autorizado à acessar essa funcionalidade", {
                variant: 'warning',
            });
            return null;
        }
        
    }

    const handleClose = () => {
        setDialogOpen(false);
        setAcknowledgeDialog(false);
    };

    const structureRow = (entitiesValues: Array<Alarm>) => {
        let alarms: Array<AlarmRow> = []
        entitiesValues.forEach( alarm => {
            alarms.push({
                id: alarm.id.toString(),
                teleObjectId: alarm.teleObjectId,
                group: alarm.group,
                severityId: alarm.severityId,
                severityName: alarm.severityName,
                severityColor: alarm.severityColor,
                description: alarm.description,
                macroRegionName: alarm.macroRegionName,
                regionName: alarm.regionName,
                powerStationId: alarm.powerStationId,
                powerStationName: <Link href="#" onClick={(event) => handleNavigationPowerPlant(event, alarm.powerStationId)} underline="hover">{alarm.powerStationName}</Link>,
                skidId: alarm.skidId,
                skidName:<Link href="#" onClick={(event) => handleNavigationSkid(event, alarm.powerStationId, alarm.skidId)} underline="hover">{alarm.skidName}</Link>,
                equipmentId: alarm.equipmentId,
                equipmentName: alarm.equipmentName,
                clearDate: alarm.clearDate ? DateFormat(alarm.clearDate) : "",
                acknowledgementDate: alarm.acknowledgementDate ? DateFormat(alarm.acknowledgementDate) : "",
                dateTime: DateFormat(alarm.dateTime),
                userName: alarm.userName,
                actionsConfig: [
                    {actionName: "grouping", disabled: false},
                    {actionName: "note", disabled: false},
                    {actionName: "acknowledge", disabled: isAuthorizedToAcknowledge ? (alarm.acknowledgementDate != null) : true},
                    {actionName: "finalize", disabled: isAuthorizedToFinalize ? (alarm.clearDate != null) : true}
                ],
            });
        });
        setRows(alarms);
    }

    const entitiesRef = useRef(false);
    useEffect(() => {
        if(!entitiesRef.current){
            entitiesRef.current = true;
        }
    },[entities]);

    const columnsRef = useRef(false);
    useEffect(() => {
        if(!columnsRef.current){
            columnsRef.current = true;
        }
    },[initColumns]);

    useEffect(() => {
        if (isArray(entities) && entities.length > 0) {
            structureRow(entities);
        }else {
            setRows([]);
        }
    }, [entities]);

    return (
        <>
            <ADataGrid
                hideFilters={false}
                showFilterOptions={false}
                hideSelection={false}
                size={"small"}
                columns={initColumns}
                rows={rows}
                page={pagedSearchParams.page}
                loading={false}
                totalOfRecords={totalOfRecords || 0}
                rowsPerPage={pagedSearchParams.size}
                onFiltersChange={handleOnFiltersChange}
                onColorSelect={handleOnColor}
                actionColumnWidth={170}
                disableActionsHeaderIcons={true}
                actions={
                    <>
                        <IconButton name="grouping" size={"small"} aria-label="Fact check" onClick={handleOnRowOpen}>
                            <Tooltip title="Agrupamento">
                                <FactCheck/>
                            </Tooltip>
                        </IconButton>

                        <IconButton name="note" size={"small"} aria-label="Border color" onClick={handleOnNotionOpen}>
                            <Tooltip title="Adicionar nota">
                                <BorderColor />
                            </Tooltip>
                        </IconButton>
                        <IconButton name="acknowledge" size={"small"} aria-label="Fact check" onClick={handleAcknowledgeClick} disabled={!isAuthorizedToAcknowledge}>
                            <Tooltip title="Reconhecer alarme">
                                <CheckCircleIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton name="finalize" size={"small"} aria-label="Border color" onClick={handleClickOpen} disabled={!isAuthorizedToFinalize}>
                            <Tooltip title="Finalizar alarme">
                                <CancelIcon />
                            </Tooltip>
                        </IconButton>
                    </>
                }
            />
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                { groupOpen && !notionOpen ?
                    <AlarmGroup alarm={entityToModel} />
                    :
                    <AlarmNotion alarm={entityToModel} />
                }
            </Modal>
            <Dialog open={dialogOpen || acknowledgeDialog} onClose={handleClose}>
                <DialogTitle>{`Confirmar ${dialogOpen ? 'finalização' : 'reconhecimento'}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza de que deseja {dialogOpen ? 'finalizar' : 'reconhecer'} este alarme?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={dialogOpen ? finalizeAlarms : alarmAcknowledges} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AlarmPage;