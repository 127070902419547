import {FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {MenuProps} from "../../../../Utils/PropsStyles";
import * as React from "react";
import { useEffect, useState} from "react";
import {PowerStationData, City, State} from "../../../../Shared/Types/PowerStation";
import {FindAllCitiesByStateId, FindAllStatesReactQuery} from "../PowerStationReactQuery";
import {validateEmail, validateLatitude, validateLongitude} from "../../../../Utils/RegexUtils";
import {TextRequired} from "../../../../Utils/ElementsHtmlUtil";
import { NumericFormat } from 'react-number-format';
import {CustomTextField} from "../../../../Shared/Components/CustomTextField"

interface Props {
    data: PowerStationData;
    onSaveInfo: any;
}

export const PowerStationFormInfoPage = ({onSaveInfo, data}: Props) => {
    const [powerStationName, setPowerStationName] = useState(data?.name);
    const [shortName, setShortName] = useState(data?.shortName);
    const [powerStationState, setPowerStationState] = useState<State>(data?.state );
    const [powerStationCity, setPowerStationCity] = useState<City | null>(data?.city);
    const [cep, setCep] = useState(data?.zip);
    const [lat, setLat] = useState<string>(data?.lat.toString());
    const [long, setLong] = useState<string>(data?.long.toString());
    const [adminName, setAdminName] = useState(data?.adminName);
    const [adminEmail, setAdminEmail] = useState(data?.adminEmail);
    const [adminPhone, setAdminPhone] = useState(data?.adminPhone);
    const [stateList, setStateList] = useState<Array<State>>([])
    const [cityList, setCityList] = useState<Array<City>>([])
    const [enabled, setEnabled] = useState<boolean>(data?.enabled)
    const mutationFindAllStates = FindAllStatesReactQuery();
    const mutationFindAllCities = FindAllCitiesByStateId();

    useEffect(() => {
        let states : State[] = mutationFindAllStates.data ?? []
        states.unshift({
            id: -1,
            name: "Selecione o estado"
        })
        setStateList(states)
    }, [mutationFindAllStates.data]);

    useEffect(() => {
        if(powerStationState != null && powerStationState.id != -1){
            mutationFindAllCities.mutate(powerStationState.id,{
                onSuccess: (data) => {
                    setCityList(data)
                },
                onError: () => {
                    setCityList([])
                }
            })
        }
    }, [powerStationState]);
    useEffect(() => {
        if(powerStationCity != null){
            const selectedCity = cityList.find((city: City) => city.id === powerStationCity.id)
            setPowerStationCity(selectedCity ?? null)
        }

    }, [cityList]);
    useEffect(() => {
        setPowerStationName(data.name)
        setShortName(data.shortName)
        setPowerStationState(data.state)
        setPowerStationCity(data.city)
        setCep(data.zip)
        setLat(data.lat.toString())
        setLong(data.long.toString())
        setAdminName(data.adminName)
        setAdminEmail(data.adminEmail)
        setAdminPhone(data.adminPhone)
        setEnabled(data.enabled)
    }, [data]);
    useEffect(() => {
        console.log("ENABLED", enabled)
        onSaveInfo({
            name: powerStationName,
            shortName: shortName,
            state: powerStationState,
            city: powerStationCity,
            zip: cep,
            lat: lat === '' ? '' : Number(lat),
            long: long === '' ? '' : Number(long),
            adminName: adminName,
            adminEmail: adminEmail,
            adminPhone: adminPhone,
            enabled: enabled
        })
    }, [powerStationName, shortName, powerStationState, powerStationCity,
        cep,lat,long , adminName, adminEmail, adminPhone, enabled]);
    return (
            <Grid container spacing={2} sx={{paddingLeft: 2}}>
                <Grid item xs={12} sm={6}>
                    <Box style={{marginBottom: 20, paddingTop: 20}}>
                        <InputLabel id="type-name">Nome da usina{TextRequired()}</InputLabel>
                        <TextField
                            error={powerStationName === ""}
                            fullWidth
                            id="type-name"
                            variant="standard"
                            placeholder={"Digite o nome da usina"}
                            onChange={(e) => setPowerStationName(e.target.value)}
                            value={powerStationName}
                            required
                        />
                        {powerStationName === "" && (
                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box style={{marginBottom: 20, paddingTop: 20}}>
                        <InputLabel id="shortName">Sigla</InputLabel>
                        <TextField
                            fullWidth
                            id="shortName"
                            variant="standard"
                            placeholder={"Digite a sigla"}
                            onChange={(e) => setShortName(e.target.value)}
                            value={shortName}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="selectState">Estado{TextRequired()}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="selectState"
                            id="selectPowerStationState"
                            placeholder={"Selecione o estado"}
                            value={powerStationState?.id}
                            label="Estado"
                            onChange={(value) =>
                                setPowerStationState({
                                    id: Number(value.target.value)
                                })}
                            variant={"standard"}
                            MenuProps={MenuProps}
                        >
                            {
                                stateList.map((entity: any) => {
                                    if(entity.id) {
                                        return (
                                            <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                        )
                                    }

                                })
                            }
                        </Select>
                        { powerStationState.id === -1 &&
                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>

                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="selectCity">Cidade{TextRequired()}</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="selectCity"
                            id="selectCity"
                            placeholder={"Selecione a cidade"}
                            value={powerStationCity?.id ?? -1}
                            label="Cidade"
                            onChange={(value) => setPowerStationCity({
                                id: Number(value.target.value)
                            })}
                            variant={"standard"}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="">
                                Selecione a cidade
                            </MenuItem>
                            {
                                cityList.map((entity: any) => {
                                    if(entity.id) {
                                        return (
                                            <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                        )
                                    }

                                })
                            }
                        </Select>
                        { powerStationState.id === -1 &&
                            <FormHelperText id="my-helper-text-power-station">É necessário selecionar uma estado primeiro.</FormHelperText>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="cep">CEP</InputLabel>
                        <TextField
                            fullWidth
                            id="cep"
                            variant="standard"
                            placeholder={"Digite o CEP"}
                            onChange={(e) => setCep(e.target.value)}
                            value={cep}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="lat">Latitude</InputLabel>
                        <NumericFormat
                            value={lat}
                            customInput={CustomTextField}
                            onChange={(e) => setLat(e.target.value)}
                            placeholder={"Digite a latitude. Ex: 12.3456789"}
                            decimalScale={7}
                            onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                            allowNegative={true}
                        />
                        {!validateLatitude(lat) && (
                            <FormHelperText id="my-helper-text">Caracteres inválidos.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="long">Longitude</InputLabel>
                        <NumericFormat
                            value={long}
                            customInput={CustomTextField}
                            onChange={(e) => setLong(e.target.value)}
                            placeholder="Digite a longitude. Ex: 12.3456789"
                            decimalScale={7}
                            onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                            allowNegative={true}
                        />
                        {!validateLongitude(long) && (
                            <FormHelperText id="my-helper-text">Caracteres inválidos.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="adminName">Nome do responsável{TextRequired()}</InputLabel>
                        <TextField
                            required
                            error={adminName === ""}
                            fullWidth
                            id="adminName"
                            variant="standard"
                            placeholder={"Digite o nome do responsável"}
                            onChange={(e) => setAdminName(e.target.value)}
                            value={adminName}
                        />
                        {adminName === "" && (
                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="adminEmail">Email{TextRequired()}</InputLabel>
                        <TextField
                            required
                            error={adminEmail === "" || !validateEmail(adminEmail)}
                            fullWidth
                            id="adminEmail"
                            variant="standard"
                            type={"email"}
                            placeholder={"Digite o email do responsável"}
                            onChange={(e) => setAdminEmail(e.target.value)}
                            value={adminEmail}
                        />
                        {adminEmail === "" && (
                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                        ) || !validateEmail(adminEmail) && (
                            <FormHelperText id="my-helper-text">Email inválido.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <InputLabel id="adminPhone">Telefone do responsável{TextRequired()}</InputLabel>
                        <TextField
                            required
                            error={adminPhone === ""}
                            fullWidth
                            id="adminPhone"
                            variant="standard"
                            placeholder={"Digite o telefone do responsável"}
                            onChange={(e) => setAdminPhone(e.target.value)}
                            value={adminPhone}
                        />
                        {adminPhone === "" && (
                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{marginBottom: 20}}>
                        <FormControlLabel
                            control={
                                <Switch value={enabled} checked={enabled} onChange={(e) => setEnabled(e.target.checked)}/>
                            }
                            label="Habilitar usina"
                        />

                    </Box>
                </Grid>
            </Grid>
    )
}