import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";


const apiUrl = "/api/power-station-user";

const fetchPowerStationDistinct = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/power-station-distinct/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching power station user data:", error);
        return Promise.reject(error);
    }
};

export const PowerStationUserReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchPowerStationDistinct(pagedSearchParams);
        }
    });
}
const fetchPowerStationUser = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching power station user data:", error);
        return Promise.reject(error);
    }
};

export const PowerStationUserFormReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation({
        mutationFn: (pagedSearchParams: PagedSearchParams) => {
            return fetchPowerStationUser(pagedSearchParams);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados: ${error.message}`, {variant: "error"});
        }
    })
}

const savePowerStationUser = async (data: any) => {
    try {
        if (data.id != null) {
            return await axios.put(apiUrl, data).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching users data:", error);
        return Promise.reject(error);
    }
};

export const SavePowerStationUserReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();

    return useMutation(savePowerStationUser, {
        onSuccess: () => {
            enqueueSnackbar("Acesso adicionado com sucesso!", {variant: "success"});
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao adicionar acesso: ${error.message}`, {variant: "error"});
        }
    });
};

const deletePowerStationUser = async (id: number) => {
    return await axios.delete(`${apiUrl}/${id}`).then((res) => res.data);
};

export const DeletePowerStationUserReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: any) => {
            return deletePowerStationUser(id);
        },
        onSuccess: () => {
            enqueueSnackbar("Acesso removido com sucesso!", {variant: "success"});
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao remover acesso: ${error.message}`, {variant: "error"});
        }
    });
};

const deleteAllPowerStationUserByPowerStationId = async (powerStationId: number) => {
    return await axios.delete(`${apiUrl}/power-station/${powerStationId}`).then((res) => res.data);
};

export const DeleteAllPowerStationUserReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: any) => {
            return deleteAllPowerStationUserByPowerStationId(id);
        },
        onSuccess: () => {
            enqueueSnackbar("Acessos removido com sucesso!", {variant: "success"});
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao remover acesso: ${error.message}`, {variant: "error"});
        }
    });
};

const fetchPowerStationUserById = async (id: number) => {
    return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
};

export const FindPowerStationUserByIdReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchPowerStationUserById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um acesso: ${error.message}`, {variant: "error"});
        }
    })
};


const fetchUserByPowerStationId = async (id: number) => {
    return await axios.get(`${apiUrl}/user/power-station/${id}`).then((res) => res.data);
};

export const FindAllUserByPowerStationIdReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchUserByPowerStationId(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um acesso: ${error.message}`, {variant: "error"});
        }
    })
};

const fetchPowerStationList = async () => {
    return await axios.get(`${apiUrl}/power-station`).then((res) => res.data);
};

export const FindAllPowerStationsReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchPowerStationList();
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um acesso: ${error.message}`, {variant: "error"});
        }
    })
};