import {QueryClient, QueryClientProvider} from "react-query";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import {ACard} from "@atiautomacao/ati-ui-library";
import {PowerStationFormInfoPage} from "./components/PowerStationFormInfoPage";
import {PowerStationFormParamsPage} from "./components/PowerStationFormParamsPage";
import {PowerStationFormExpectedPage} from "./components/PowerStationFormExpectedPage";
import {PowerStationFormEditTeleObjectPage} from "./components/PowerStationFormEditTeleObjectPage"
import {FindNumerOfEquipmentBySkidReactQuery, FindPowerStationByIdReactQuery} from "./PowerStationReactQuery";
import {
    BrokerPropsPowerStation,
    City,
    ExpectedDataProps,
    PowerStationData,
    PowerStationParams,
    PowerStationType,
    Skid,
    State
} from "../../../Shared/Types/PowerStation"
import {PowerStationFormSkidPage} from "./components/PowerStationFormSkidPage";
import axios from "axios";
import {useSnackbar} from "notistack";
import {parse} from "date-fns";
import {ptBR} from "date-fns/locale";
import {validateLatitude, validateLongitude} from "../../../Utils/RegexUtils";
import {PowerStationGridBrokerPage} from "./components/PowerStationGridBrokerPage";

export const sortSkid = (data:Array<Skid>) => {
    return data.sort((a, b) => a.name.localeCompare(b.name))
}
export const PowerStationFormPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const id = location.state?.id as number;
    const [powerStationId, setPowerStationId] = useState<number | null>(null);
    const [powerStationDetailsId, setPowerStationDetailsId] = useState<number | null>(null);
    const navigate = useNavigate();
    const mutationFindById = FindPowerStationByIdReactQuery();
    const mutationFindNumberOfEquipmentBySkid = FindNumerOfEquipmentBySkidReactQuery();
    const [value, setValue] = useState('data');
    const [paramsData, setParamsData] = useState<PowerStationParams>({
        powerCapacityNominal: 0,
        maxPower: 0,
        numberOfUG: 0,
        expectedGenerateEnergy: 0,
        expectedYield: 0,
        expectedPerformanceRatio: 0,
        expectedIrradiance: 0,
        operationStartedAt: null,
        operationMode: "L",
        meterInterval: 0,
        inverterInterval: 0,
        meterConst: 0,
        moduleTempCoef: 0
    })
    const [skidData, setSkidData] = useState<Array<Skid>>([]);
    const [teleObjectData, setTeleObjectData] = useState<any>([])
    const [powerStationData, setPowerStationData] = useState<PowerStationData>({
        id: null,
        name: "",
        shortName: "",
        state:{
            id: -1,
            name: "Selecione o estado"
        },
        city: null,
        zip:"",
        lat: 0,
        long: 0,
        adminName: "",
        adminEmail: "",
        adminPhone: "",
        enabled: true
    });
    const [brokerData, setBrokerData] = useState<BrokerPropsPowerStation[]>([{
        id: null,
        name: "",
        ipAddress: "",
        port: NaN,
        rxQueue: "",
        txQueue: "",
        notificationQueue: "",
        powerStation: undefined,
        gwid: NaN
    }]);
    const [expectedDataList, setExpectedDataList] = useState<Array<ExpectedDataProps>>([]);
    const [expectedDataDeleted, setExpectedDataDeleted] = useState<Array<number>>([]);
    const [skidsDeleted, setSkidsDeleted] = useState<Array<number>>([]);
    const [numberOfEquipmentBySkid, setNumberOfEquipmentBySkid] = useState<Array<any>>([]);
    async function save(data:any, apiUrl: string)  {
        try {
            const response = await axios.post(apiUrl, data);
            return response.data;

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async function update(data:any, apiUrl: string)  {
        try {
            const response = await axios.put(apiUrl, data);
            return response.data;

        } catch (error:any) {
            throw error;
        }
    }

    async function getById(id: number, apiUrl: string) {
        try {
            const response = await axios.get(`${apiUrl}/${id}`);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    async function deleteAllById(data:any, apiUrl: string)  {
        await save(data, apiUrl)
    }

    function hasEmptyStringAttributes<T>(data: T): boolean {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const value = data[key as keyof T];
                if (typeof value === 'string' && value.trim() === '') {
                    return true;
                }
            }
        }
        return false;
    }

    const hasEmptyOrNullAttributes = (obj: BrokerPropsPowerStation): boolean => {
        return Object.values(obj).some(value =>
            value === undefined ||
            value === "" ||
            (typeof value === "number" && isNaN(value))
        );
    }


    useEffect(() => {
        if(id){
            mutationFindById.mutate(id, {
                onSuccess: (data) => {
                    const result = data?.data as PowerStationType
                    setPowerStationId(result.id)
                    setPowerStationDetailsId(result.powerStationDetails.id)
                    let city : City = {
                        id: result.powerStationDetails.cityEntity.id,
                        name: result.powerStationDetails.cityEntity.name
                    }
                    let state : State = {
                        id: result.powerStationDetails.cityEntity.state.id,
                        name: result.powerStationDetails.cityEntity.state.name
                    }
                    let powerStation:PowerStationData = {
                        id: result.id,
                        name: result.name,
                        shortName: result.shortName,
                        state: state,
                        city: city,
                        zip:result.powerStationDetails.zipCode,
                        lat: result.powerStationDetails.latitude,
                        long: result.powerStationDetails.longitude,
                        adminName: result.powerStationDetails.adminName,
                        adminEmail: result.powerStationDetails.adminEmail,
                        adminPhone: result.powerStationDetails.phone1,
                        enabled: result.enabled
                    };

                    let powerStationParams: PowerStationParams = {
                        powerCapacityNominal: result.powerCapacityNominal,
                        maxPower: result.maxPower,
                        numberOfUG: result.numberOfUG,
                        expectedGenerateEnergy: result.expectedGenerateEnergy,
                        expectedYield: result.expectedYield,
                        expectedPerformanceRatio: result.expectedPerformanceRatio,
                        expectedIrradiance: result.expectedIrradiance,
                        operationStartedAt: result.operationStartedAt,
                        operationMode: result.syncOperationMode,
                        inverterInterval: result.inverterInterval,
                        meterInterval: result.meterInterval,
                        meterConst: result.meterConst,
                        moduleTempCoef: result.moduleTemperatureCoefficient
                    }

                    if(result.brokerList.length > 0) {
                        let brokerPowerStation = result.brokerList;
                        setBrokerData(brokerPowerStation);
                    }

                    setPowerStationData(powerStation)
                    const sortedSkids = sortSkid(result.skidList)
                    setSkidData(sortedSkids)
                    setParamsData(powerStationParams)
                }
            });

        }
    }, []);

    useEffect(() => {
        if(powerStationId != null){
            mutationFindNumberOfEquipmentBySkid.mutate(powerStationId, {
                onSuccess:(data:any)=>{
                    setNumberOfEquipmentBySkid(data.data)
                }
            })
            getById(id,'/api/expected-data/find-all').then(
                response=>{

                    const expectedData = response.data.map((exp:ExpectedDataProps) => {
                        return {
                            ...exp,
                            date: parse(exp.date.toString(), 'yyyy-MM-dd', new Date(),{locale: ptBR})
                        }
                    })
                    setExpectedDataList(expectedData)
                }
            )
        }
    }, [powerStationId]);

    useEffect(() => {
        if(skidData.length > 0){
            let skids:Array<Skid> = [...skidData]
            const skidMap = new Map<number, number>();
            numberOfEquipmentBySkid.forEach(skid => {
                skidMap.set(skid.skidId, skid.numberOfEquipment);
            });

            const newSkidData = skids.map(skid => {
                if (skid.id != null && skidMap.has(skid.id)) {
                    skid.numberOfEquipment = skidMap.get(skid.id) ?? 0;
                }else{
                    skid.numberOfEquipment = 0
                }
                return skid;
            });
            const sortedSkids = sortSkid(newSkidData)
            setSkidData(sortedSkids)
        }
    }, [numberOfEquipmentBySkid]);

    const handleChange = (event:any, newValue:any) => {
        setValue(newValue);
    };
    const onSaveInfo = (infoData: PowerStationData) => {
        setPowerStationData(infoData);
    }

    const onSaveParams = (paramsData: any) => {
        const params = {
            ...paramsData,
            numberOfUG: skidData.length
        }
        setParamsData(params)
    }

    const onSaveExpectedData = (expectedData: Array<ExpectedDataProps>) => {
        setExpectedDataList(expectedData)
    }

    const onSaveSkid = (skidData: Array<Skid>) => {
        setSkidData(skidData)
    }

    const onSaveBroker = (broker: BrokerPropsPowerStation[]) => {
        setBrokerData(broker);
    }

    const onSaveTeleObject = (teleObjectData: any) => {
        setTeleObjectData(teleObjectData);
    };

    const onDeleteSkid = (skidIds: Array<number>) => {
        setSkidsDeleted(skidIds)
    }

    const onDeleteExpectedData = (deletedIds: Array<number>) => {
        setExpectedDataDeleted(deletedIds)
    }
    const saveOrUpdate = () => {

        if(!hasEmptyStringAttributes(powerStationData) && skidData.length > 0 && paramsData.operationStartedAt != null) {
            if(!validateLatitude(powerStationData.lat.toString()) || !validateLongitude(powerStationData.long.toString())){
                enqueueSnackbar("Preencha os campos latitude e longitude com dados válidos.", {variant: 'error'})
                return
            }
            const powerStationDTO = {
                powerStation: {
                    name: powerStationData.name,
                    syncOperationMode: paramsData.operationMode,
                    shortName: powerStationData.shortName,
                    powerStationDetails: {
                        zipCode: powerStationData.zip,
                        latitude: powerStationData.lat,
                        longitude: powerStationData.long,
                        adminName: powerStationData.adminName,
                        adminEmail: powerStationData.adminEmail,
                        phone1: powerStationData.adminPhone,
                        cityEntity: {
                            id: powerStationData?.city?.id,
                            state: {
                                id: powerStationData.state.id
                            }
                        }
                    },
                    powerCapacityNominal: paramsData.powerCapacityNominal,
                    maxPower: paramsData.maxPower,
                    numberOfUG: skidData.length,
                    expectedGenerateEnergy: paramsData.expectedGenerateEnergy,
                    expectedYield: paramsData.expectedYield,
                    expectedPerformanceRatio: paramsData.expectedPerformanceRatio,
                    expectedIrradiance: paramsData.expectedIrradiance,
                    operationStartedAt: paramsData.operationStartedAt,
                    inverterInterval: paramsData.inverterInterval,
                    meterInterval: paramsData.meterInterval,
                    meterConst: paramsData.meterConst,
                    moduleTemperatureCoefficient: paramsData.moduleTempCoef,
                    skidList: skidData,
                    brokerList: [],
                    enabled: powerStationData.enabled
                }
            }
            if (powerStationId === null) {
                handleSave(powerStationDTO)
            } else {
                handleUpdate(powerStationDTO)
            }
        }else{
            enqueueSnackbar("Verifique os campos obrigatórios antes de tentar salvar.", {variant: 'error'})
        }
    }
    const handleSave = async(powerStationDTO:any) => {
        save(powerStationDTO, "api/power-station").then(
            response => {
                const newExpectedDataList:ExpectedDataProps[] = expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: response.data.powerStation.id
                        }
                    }
                })

                save(newExpectedDataList, "api/expected-data").then(
                    () => {
                        if(hasEmptyOrNullAttributes(brokerData[0])){
                            enqueueSnackbar("Usina salva com sucesso.", {variant: 'success'})
                            navigate(SystemRoutes.SETTINGS_POWER_PLANT)
                        }
                    }
                );

                brokerData.forEach(broker => {
                    if(broker.powerStation === undefined && powerStationId) {
                        broker.powerStation = { id: powerStationId }
                    }
                    if(!hasEmptyOrNullAttributes(broker)){
                        save(broker, "api/broker").then(
                            () => {
                                enqueueSnackbar("Usina salva com sucesso.", {variant: 'success'})
                                navigate(SystemRoutes.SETTINGS_POWER_PLANT)
                            }
                        );

                    }
                })
                if(hasEmptyOrNullAttributes(brokerData[0]) && newExpectedDataList.length === 0){
                    enqueueSnackbar("Usina salva com sucesso.", {variant: 'success'})
                    navigate(SystemRoutes.SETTINGS_POWER_PLANT)
                }
            }
        ).catch(
            error=> {
                console.log(error)
                enqueueSnackbar("Error ao salvar usina.", {variant: 'error'})
            }
        )
    }

    const handleUpdate = async(powerStationDTO:any) => {
        const newDTO = {
            powerStation: {
                ...powerStationDTO.powerStation,
                powerStationDetails:{
                    id: powerStationDetailsId,
                    ...powerStationDTO.powerStation.powerStationDetails
                },
                id: powerStationId,
                expectedDataList: expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: powerStationId
                        }
                    }
                })
            }
        }

        try {
            if(skidsDeleted.length > 0) {
                await deleteAllById(skidsDeleted, "api/skid/delete-all")
            }
        }catch (error:any){
            const status = error.response.status
            if(status === 409){
                enqueueSnackbar("Existem dados vinculados as skids que voce tentou remover, por favor contactar o administrador. ", {variant: 'error'})
            }else{
                enqueueSnackbar("Error ao remover skids.", {variant: 'error'})
            }
            navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            return;
        }

        try{
            if(expectedDataDeleted.length > 0){
                await deleteAllById(expectedDataDeleted,"/api/expected-data/delete-all")
            }
        }catch (error){
            enqueueSnackbar("Error ao remover dados esperados.", {variant: 'error'})
            navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            return;
        }

        update(newDTO, "api/power-station").then(
            response => {
                const newExpectedDataList:ExpectedDataProps[] = expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: response.data.powerStation.id
                        }
                    }
                })
                return update(newExpectedDataList, "api/expected-data").then(response => {
                    enqueueSnackbar("Usina atualizada com sucesso. Verificando o restante dos dados..", { variant: 'success' });

                    brokerData.forEach(broker => {
                        if(broker.powerStation == null && powerStationId) {
                            broker.powerStation = { id: powerStationId }
                        }
                        if(!hasEmptyOrNullAttributes(broker)){
                            update(broker, "api/broker").then(
                                () => {
                                    enqueueSnackbar("Brokers salvos com sucesso. Verificando o restante dos dados..", {variant: 'success'})
                                }
                            );
                        }
                    })

                    return update(teleObjectData, "api/teleobject").then(response => {
                        let commandValues: any[] = [];
                        teleObjectData.filter((tb: any) => tb.commandValue !== null).forEach((tob: any) => {
                            tob.commandValue.forEach((cmd: any) => {
                                commandValues.push(cmd);
                            });
                        })

                        commandValues.forEach((cv: any) => {
                            cv.teleObjectConfig = response.data.find((dt: any) => dt.teleObjectConfig.id === cv.teleObjectConfig)?.teleObjectConfig;
                        })
                        let commandsToSave = commandValues.filter(cm => cm.teleObjectConfig !== null && cm.teleObjectConfig !== undefined);
                        return update(commandsToSave, "api/command-value").then(response => {
                            console.log(response);
                            navigate(SystemRoutes.SETTINGS_POWER_PLANT);
                        });
                    }).catch(error => {
                        const status = error.response.status
                        if (status === 409) {
                            enqueueSnackbar("Erro ao cadastrar um Tele Objeto com os dados inseridos. Por favor, tente novamente!", { variant: 'error' })
                        } else {
                            enqueueSnackbar("Erro ao atualizar os Tele Objetos.", { variant: 'error' })
                        }
                        navigate(SystemRoutes.SETTINGS_POWER_PLANT);
                    });
                });
            }
        ).catch(
            error => {
                const status = error.response.status
                if(status === 409){
                    enqueueSnackbar("Existem dados vinculados a usina que voce tentou editar, por favor contactar o administrador. ", {variant: 'error'})
                }else{
                    enqueueSnackbar("Error ao remover skids.", {variant: 'error'})
                }
                navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            }
        )
    }

    const [content, setContent] = useState<JSX.Element>();
    useEffect(() => {
        if(value === 'data'){
            setContent(<PowerStationFormInfoPage data={powerStationData} onSaveInfo={onSaveInfo}/>)
        }else if(value === 'broker'){
            setContent(<PowerStationGridBrokerPage onSaveBroker={onSaveBroker} powerStationId={id}/>)
        }else if(value === 'params'){
            setContent(<PowerStationFormParamsPage onSaveParams={onSaveParams} data={paramsData}/>)
        }
        else if(value === 'expected'){
            setContent(<PowerStationFormExpectedPage onDelete={onDeleteExpectedData} initData={expectedDataList} deletedIds={expectedDataDeleted} onSaveParams={onSaveExpectedData} powerStationId={id ?? null}/>)
        }
        else if(value === 'skid'){
            setContent(<PowerStationFormSkidPage onDeleteSkid={onDeleteSkid} data={skidData} deletedIds={skidsDeleted} onSaveSkid={onSaveSkid}/>)
        }
        else if(value === 'teleObject'){
            setContent(<PowerStationFormEditTeleObjectPage powerStationId={id} powerStationName={powerStationData.name} onSaveTeleObject={onSaveTeleObject}/>)
        }
    }, [value, mutationFindById.data]);
    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={saveOrUpdate} systemRoutes={SystemRoutes.SETTINGS_POWER_PLANT}/>
            <Box style={{paddingTop: 64}}>
                        <ACard styleProps={{
                            headerStyle: {padding: 0}
                        }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    value="data"
                                    label="Dados"
                                    wrapped
                                />
                                <Tab value="broker" label="Broker" />
                                <Tab value="expected" label="Esperados" />
                                <Tab value="params" label="Parâmetros" />
                                <Tab value="skid" label="Skids" />
                                <Tab value="teleObject" label="Tele Objetos" />
                            </Tabs>
                            {content != null && content}
                        </ACard>
            </Box>
        </>
    )
}

export function PowerStationHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <PowerStationFormPage/>
        </QueryClientProvider>
    )
}
