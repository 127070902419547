import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Select
} from "@mui/material";
import TextField from '@mui/material/TextField';
import ChartConfigDataItem, {mapPositionType} from "./ChartConfigDataItem";
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {ChartConfigType} from "../../Shared/Types/chartConfigType";
import {isBefore, subDays, subMonths, subWeeks, subYears} from "date-fns";
import {ChartAxisType} from "../../Shared/Types/chartAxisType";
import {ChartSeriesType} from "../../Shared/Types/chartSeriesType";
import {LocalDateTimeFormatISO} from "../../Utils/DateFormatPatternUtils";
import MenuItem from "@mui/material/MenuItem";
import {SelectChangeEvent} from "@mui/material/Select";
import {SelectedIds} from "./AnalysisEditPage";
import {isEqual} from "lodash";
import {useAppSelector} from "../../Config/Hooks";
import {hasPermission} from "../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../Config/Constants";

interface Props {
    options: {
        chartConfig: ChartConfigType;
        index: number;
        filters?: any;
    },
    onPreview: any;
    onDeleteChartConfig: any;
    onEdit?: any;
}

export const mapConfigType: Record<string, any> = {
    'SINGLE_AXIS': {name:'Individual',ordinal: 0},
    'MULTI_AXIS': {name:'Unificado', ordinal: 1}
}
export const mapPeriodType: Record<string, any> = {
    'SPECIFIC': {name:'specific',ordinal: 0},
    'RECENT': {name:'recent', ordinal: 1}
}

export const ChartConfig = ({options, onPreview, onEdit, onDeleteChartConfig}:Props) => {

    const [descriptionChartConfig, setDescriptionChartConfig] = React.useState<string>(options?.chartConfig?.description ?? "");
    const [nameChartConfig, setNameAnalysisChart] = React.useState<string>(options.chartConfig.name ?? "");

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleAccordionExpandChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const getMapConfigType = (configType: string | number) => {
        if (typeof configType === 'string') {
            return mapConfigType[configType];
        } else {
                const configTypeString = Object.keys(mapConfigType).find((key) => mapConfigType[key].ordinal === configType);
                if (configTypeString) {
                    return mapConfigType[configTypeString.toString()];
                }
        }
    };
    const getMapPeriodType = (periodType: string | number) => {
        if (typeof periodType === 'string') {
            return mapPeriodType[periodType.toUpperCase()];
        } else {
            const periodTypeString = Object.keys(mapPeriodType).find((key) => mapPeriodType[key].ordinal === periodType);
            if (periodTypeString) {
                return mapPeriodType[periodTypeString.toString()];
            }
        }
    };

    const [chartConfigPeriod, setChartConfigPeriod] = React.useState(getMapPeriodType(options.chartConfig.periodType)?.name ?? 'specific');
    const handleChartConfigPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChartConfigPeriod((event.target as HTMLInputElement).value);
    };

    const [groupedByPeriod, setGroupedByPeriod] = React.useState(options?.chartConfig?.grouping ?? "day");
    const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupedByPeriod((event.target as HTMLInputElement).value);
        if(!(event.target as HTMLInputElement).value.includes("none")){
            // Ensure final data will always be 23:59:59
            setEndDateTime(handleEndDateTimeToSpecific(endDateTime));
        }
    };

    const [groupingRecentByPeriod, setGroupingRecentByPeriod] = React.useState(options.chartConfig.groupingRecentByPeriod ?? "day");

    const handleRecentByPeriodChange = (event: SelectChangeEvent) => {
        setGroupingRecentByPeriod(event.target.value);
    };

    const [displayDataPoints, setDisplayDataPoints] = React.useState(options.chartConfig.displayDataPoints ?? 1);
    const handleDisplayDataPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!event.target.value || parseInt(event.target.value) <= 0) {
            setDisplayDataPoints(1);
        } else {
            setDisplayDataPoints(Number(event.target.value));
        }
    }

    const [recentPeriod, setRecentPeriod] = React.useState(options.chartConfig.recentPeriod ?? 7);

    const handleStartDateTimeToRecent = (value: number) => {
        let now = new Date();
        let newStartDateTime: Date | null = null;
        if(value === 0){
            return startDateTime;
        }
        if(groupingRecentByPeriod === 'none'){
            newStartDateTime = subDays(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'day'){
            newStartDateTime = subDays(now, value -1);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'week'){
              newStartDateTime = subWeeks(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'month'){
              newStartDateTime = subMonths(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'year'){
              newStartDateTime = subYears(now, value -1);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        }
        return newStartDateTime;
    }

    const handleRecentPeriod = (event: React.ChangeEvent<HTMLInputElement>) =>{
        if(!event.target.value || parseInt(event.target.value) <= 0) {
            setRecentPeriod(1);
            handleStartDateTimeToRecent(1)
        } else {
            setRecentPeriod(Number(event.target.value));
            handleStartDateTimeToRecent(Number(event.target.value))
        }
    }

    const [timeByMinute, setTimeByMinute] = React.useState(options?.chartConfig?.minute);
    const handleTimeMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeByMinute(parseInt((event.target as HTMLInputElement).value));
    };

    const [timeByHour, setTimeByHour] = React.useState(options?.chartConfig?.hour);

    const handleTimeHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeByHour(parseInt((event.target as HTMLInputElement).value));
    };

    const [endDateTime, setEndDateTime] = React.useState<Date>(options.chartConfig ? new Date(options?.chartConfig?.endDateTime) : new Date());

    const handleEndDateTimeByGrouped = (newValue:Date) => {
        const now = new Date(newValue);
        if(!groupedByPeriod.includes("none")){
            return handleEndDateTimeToSpecific(now);
        }
        return now;
    }

    const handleStartDateTime = (newValue:Date) => {
        const now = new Date(newValue);
        now.setHours(0)
        now.setMinutes(0)
        now.setSeconds(0)
        return now;
    }
    const [startDateTime, setStartDateTime] = React.useState<Date | null>(handleStartDateTime(options.chartConfig ? new Date(options.chartConfig.startDateTime) : new Date()));


    const handleEndDateTimeToSpecific = (newValue:Date) => {
        const now = new Date(newValue);
        now.setHours(23)
        now.setMinutes(59)
        now.setSeconds(59)
        return now;
    }
    const [yAxisList, setYAxisList] = React.useState<Array<ChartAxisType>>(options?.chartConfig.yAxisList);
    const [xAxisList, setXAxisList] = React.useState<Array<ChartAxisType>>(options?.chartConfig.xAxisList);
    const [seriesList, setSeriesList] = React.useState<ChartSeriesType[]>(options.chartConfig.seriesList);
    const [isValidateYAxis, setIsValidateYAxis] = React.useState<boolean>(true);


    const handlePreview = () => {
        console.log("yAxisList", yAxisList);
        console.log("xAxisList", xAxisList);
        console.log("seriesList", seriesList);

        let newStartDateTime: Date | null = null;
        let newEndDateTime: Date | null = endDateTime;
        let newGroupingRecentByPeriod: string | null = null;
        if(chartConfigPeriod === 'recent'){
           newStartDateTime = handleStartDateTimeToRecent(recentPeriod)
            newEndDateTime = new Date();
           newGroupingRecentByPeriod = groupingRecentByPeriod;
        }
        const randomNumber = Math.floor(Math.random() * 9007199254740991) + 1;

        const chartConfig: ChartConfigType = {
            id: options.chartConfig?.id,
            tag: options.chartConfig?.tag ?? randomNumber,
            name: nameChartConfig,
            description: descriptionChartConfig,
            startDateTime: LocalDateTimeFormatISO(newStartDateTime ?? startDateTime),
            endDateTime: LocalDateTimeFormatISO(handleEndDateTimeByGrouped(newEndDateTime)),
            periodType: getMapPeriodType(chartConfigPeriod).ordinal,
            recentPeriod: recentPeriod,
            minute: timeByMinute,
            hour: timeByHour,
            grouping: groupedByPeriod,
            groupingRecentByPeriod: newGroupingRecentByPeriod,
            displayDataPoints: displayDataPoints,
            configType: getMapConfigType(options.chartConfig.configType).ordinal,
            analysis: null,
            yAxisList: yAxisList,
            xAxisList: xAxisList,
            seriesList:seriesList
        }
        onPreview(chartConfig, options.index)
    }

    const getMapPositionType = (position: string | number) => {
        if (typeof position === 'string') {
            return mapPositionType[position].ordinal;
        } else {
            return position
        }
    };

    function validateMultiYAxis(newYAxisList: ChartAxisType[]) {

        const unityByIdTeleObjetDistinct:Array<number|null> = Array.from(new Set());
        const unityByIdManagerDistinct:Array<number|null> = Array.from(new Set());

        newYAxisList.forEach(yAxis => {
            if(yAxis.chartSourceConfig.teleObject != null) {
                if(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id) {
                    unityByIdTeleObjetDistinct.push(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id)
                }else {
                    unityByIdTeleObjetDistinct.push(null);
                }
            } else if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.id != null) {
                unityByIdManagerDistinct.push(yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.id);
            }
        })

        let union:Array<number|null> = [];
        if(unityByIdTeleObjetDistinct && unityByIdTeleObjetDistinct.length > 0) {
            union.push(...unityByIdTeleObjetDistinct);
        }
        if(unityByIdManagerDistinct && unityByIdManagerDistinct.length > 0 ) {
            union.push(...unityByIdManagerDistinct);
        }
        const unionUnityDistinct = Array.from(new Set(union.map(item => item)));

        const yFilterUnityByManagerial :ChartAxisType[] = [];
        const yFilterSecondUnityByManagerial:ChartAxisType[] = [];
        const yFilterByTelemedida :ChartAxisType[] = [];
        const yFilterSecondUnityByTelemedida:ChartAxisType[] = [];

        newYAxisList.forEach(yAxis => {
            if(yAxis.chartSourceConfig.teleObject != null) {
                if((unionUnityDistinct[0] === null && !yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id )|| yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id === unionUnityDistinct[0]){
                    yFilterByTelemedida.push(yAxis)
                }
                if((unionUnityDistinct[1] === null && !yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id )|| yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.id === unionUnityDistinct[1]){
                    yFilterSecondUnityByTelemedida.push(yAxis)
                }
            } else if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.id != null) {
                if(yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.id === unionUnityDistinct[0]) {
                    yFilterUnityByManagerial.push(yAxis);
                }else if(yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.id === unionUnityDistinct[1]) {
                    yFilterSecondUnityByManagerial.push(yAxis);
                }
            }
        })
        if(yFilterByTelemedida.length < newYAxisList.length && yFilterSecondUnityByTelemedida.length < newYAxisList.length && yFilterUnityByManagerial.length < newYAxisList.length && yFilterSecondUnityByManagerial.length < newYAxisList.length) {

            const distinctChartByPosition = Array.from(new Set([...yFilterUnityByManagerial, ...yFilterByTelemedida].map(item => getMapPositionType(item.position))));
            const distinctChartByPositionSecond = Array.from(new Set([...yFilterSecondUnityByManagerial, ...yFilterSecondUnityByTelemedida].map(item => getMapPositionType(item.position))));

            if ((distinctChartByPosition.length > 1 || distinctChartByPositionSecond.length > 1) || isEqual(distinctChartByPosition, distinctChartByPositionSecond)) {
                setIsValidateYAxis(false);
            } else {
                setIsValidateYAxis(true);
            }
        } else {
            setIsValidateYAxis(true);
        }
    }


    const handleAxis = (updateY:ChartAxisType, type:string, position: any, yAxisTag: any) => {

        if(updateY.dataUnity?.includes("real")){
            setGroupedByPeriod("none");
        }

        if(yAxisList != null){
            const newYaxis = yAxisList.map((axis, i) => {
                if(axis.tag === yAxisTag){
                    return updateY
                }
                return axis
            })
            if(getMapConfigType(options.chartConfig.configType).ordinal === 1) {
                // validar se a lista ta na ordem certa
                console.log("VALIDANDO EIXOS-Y");
                validateMultiYAxis(newYaxis)
            }

            setYAxisList(newYaxis)
        }

        if(seriesList != null){
            const newSeries = seriesList.map((series) => {
                if(updateY.tag === series.tag){
                    return {
                        ...series,
                        type: type,
                        yAxisIndex: position.toString() === '2' ? 0 : 1
                    }
                }
                return series
            })
            setSeriesList(newSeries)
        }

    }

    const handleDeleteAxis = (yAxisTag: any) => {
            if(yAxisList != null){
                const newYaxis = yAxisList.filter((axis, i) => axis?.tag != yAxisTag)
                setYAxisList(newYaxis)
            }
            if(xAxisList != null){
                const newXAxis = xAxisList.filter((axis, i) => axis?.tag != yAxisTag)
                setXAxisList(newXAxis)
            }
            if(seriesList != null){
                const newSeries = seriesList.filter(series => series.tag != yAxisTag)
                setSeriesList(newSeries)
            }
    }

    const findSelectedIds = (): SelectedIds => {
        const chartConfig: ChartConfigType = options.chartConfig
        let selectedIds: SelectedIds = {
            managerialData: [],
            telemeasureIds: [],
            equipmentIds: []
        }
        chartConfig.yAxisList.forEach((item) => {
            if (item.chartSourceConfig.teleObject !== null) {
                selectedIds.telemeasureIds?.push(item.chartSourceConfig.teleObject?.id)
                selectedIds.equipmentIds?.push(item.chartSourceConfig.teleObject.equipment?.id)
            } else if (item.chartSourceConfig.aggregatedDataConfig !== null) {
                if(item.chartSourceConfig.aggregatedDataConfig.skid !== null){
                    selectedIds.managerialData.push({
                        powerStationId: null,
                        skidId: item.chartSourceConfig.aggregatedDataConfig.skid.id,
                        dbColumId: item.chartSourceConfig.aggregatedDataConfig.dbColumn?.id ?? null
                    })
                }else if(item.chartSourceConfig.aggregatedDataConfig.powerStation !== null){
                    selectedIds.managerialData.push({
                        powerStationId: item.chartSourceConfig.aggregatedDataConfig.powerStation.id,
                        skidId: null,
                        dbColumId: item.chartSourceConfig.aggregatedDataConfig.dbColumn?.id ?? null
                    })
                }
            }
        })
        return selectedIds
    }

    const inputFormatDate = groupedByPeriod.includes("none") ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_ANALYSIS
    ]);
    return (
        <Grid item padding={1} xs={12}>
            <Accordion expanded={expanded === 'config'} onChange={handleAccordionExpandChange('config')}>
            <AccordionSummary
                expandIcon={expanded === 'config' ? <CloseIcon /> : <EditIcon/>}
                aria-controls="configbh-content"
                id="configbh-header"
            >
                <Typography sx={{width: '45%',flexShrink: 0 }}>
                    {nameChartConfig}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{descriptionChartConfig}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                { nameChartConfig.length >  0 ?
                    <TextField
                        fullWidth
                        id="chart-name"
                        label="Nome"
                        variant="standard"
                        placeholder={"Digite o nome do gráfico"}
                        value={nameChartConfig}
                        onChange={(value) => setNameAnalysisChart(value.target.value)}
                    />
                    :
                    <TextField
                        fullWidth
                        error
                        helperText="Campo obrigatório."
                        id="chart-name"
                        label="Nome"
                        variant="standard"
                        placeholder={"Digite o nome do gráfico"}
                        value={nameChartConfig}
                        onChange={(value) => setNameAnalysisChart(value.target.value)}
                    />
                }

                <br/>
                <TextField
                    fullWidth
                    id="chart-description"
                    label="Descrição"
                    placeholder={"Digite uma descrição"}
                    variant="standard"
                    value={descriptionChartConfig}
                    onChange={(value) => setDescriptionChartConfig(value.target.value)}

                />
                <br/>
                <br/>
                <Box
                    component="fieldset"
                    sx={{
                        borderRadius: 2
                    }}
                >
                    <legend>Configuração</legend>

                    <Typography sx={{ color: 'text.secondary' }}>{getMapConfigType(options.chartConfig.configType).name}</Typography>
                    <RadioGroup
                        row
                        name="period-config"
                        value={chartConfigPeriod}
                        onChange={handleChartConfigPeriodChange}
                    >
                        <FormControlLabel value="specific" control={<Radio />} label="Específico" />
                        <FormControlLabel value="recent" control={<Radio />} label="Recente" />
                    </RadioGroup>
                    {chartConfigPeriod === 'specific' &&
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Início"
                                ampm = {false}
                                inputFormat={inputFormatDate}
                                value={startDateTime}
                                onChange={(newValue) => {
                                    setStartDateTime(newValue ? new Date(newValue) : handleStartDateTime(new Date()));
                                }}
                                renderInput={(params: any) =>
                                    <>
                                        {endDateTime && startDateTime && isBefore(endDateTime,startDateTime) && (
                                            <FormHelperText id="component-error-text-start" style={{color: '#EB5757'}}>
                                                Data inicial deve ser igual ou anterior a data final.
                                            </FormHelperText>
                                        )}
                                        <TextField variant={"standard"} {...params} />
                                    </>
                                }
                            />
                            <DateTimePicker
                                label="Fim"
                                ampm = {false}
                               inputFormat={inputFormatDate}
                                value={endDateTime}
                                onChange={(newValue: any) => {
                                    setEndDateTime(handleEndDateTimeByGrouped(newValue ?? new Date()));
                                }}
                                renderInput={(params: any) => <TextField variant={"standard"} {...params} />}
                            />
                        </LocalizationProvider>
                    }

                    {chartConfigPeriod === 'recent' &&
                        <Box>
                            <FormControl variant="standard" sx={{display: "inline", minWidth: 120 }}>
                                <Typography sx={{display: "inline"}}>Últimos: </Typography>
                                <TextField
                                    style={{width: 40}}
                                    type={"number"}
                                    inputProps={{"aria-valuemin": 1, pattern: '[0-9]*' }}
                                    value={recentPeriod}
                                    variant={"standard"}
                                    onChange={handleRecentPeriod}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{display: "inline", minWidth: 120 }}>
                                <Select
                                    labelId="groupedByPeriod"
                                    id="groupedByPeriod"
                                    value={groupingRecentByPeriod}
                                    onChange={handleRecentByPeriodChange}
                                    label="Age"
                                    size={"medium"}
                                >
                                    <MenuItem value="day">Dia(s)</MenuItem>
                                    {/*<MenuItem value="week">Semana(s)</MenuItem>*/}
                                    <MenuItem value="month">Mês(es)</MenuItem>
                                    <MenuItem value="year">Ano(s)</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    }
                </Box>
                <br/>
                    <Box
                        component="fieldset"
                        sx={{borderRadius: 2}}
                    >
                        <legend>Agrupar por:</legend>
                        <RadioGroup
                            row
                            name="groupedByPeriod"
                            value={groupedByPeriod}
                            onChange={handlePeriodChange}
                        >
                            { yAxisList.filter(element => element.chartSourceConfig.aggregatedDataConfig?.dbColumn?.id != null).length === 0 &&
                                <FormControlLabel value="none" control={<Radio/>} label="Nenhum"/>
                            }
                            <FormControlLabel value="day" control={<Radio/>} label="Dia"/>
                            {/*<FormControlLabel value="week" control={<Radio />} label="Semana" />*/}
                            <FormControlLabel value="month" control={<Radio/>} label="Mês"/>
                            <FormControlLabel value="year" control={<Radio/>} label="Ano"/>

                        </RadioGroup>
                        <br/>
                        {groupedByPeriod === 'minute' ?
                            <FormControl>
                                <FormLabel id="grouped-by-time">Minutos</FormLabel>
                                <RadioGroup
                                    aria-labelledby="grouped-by-time"
                                    row
                                    name="groupedBy"
                                    value={timeByMinute}
                                    onChange={handleTimeMinuteChange}
                                >
                                    <FormControlLabel value={5} control={<Radio/>} label="5"/>
                                    <FormControlLabel value={10} control={<Radio/>} label="10"/>
                                    <FormControlLabel value={15} control={<Radio/>} label="15"/>
                                    <FormControlLabel value={30} control={<Radio/>} label="30"/>
                                    <FormControlLabel value={60} control={<Radio/>} label="60"/>
                                </RadioGroup>
                            </FormControl>
                            : null
                        }
                        {groupedByPeriod === 'hour' ?
                            <FormControl>
                                <FormLabel id="grouped-by-time">Horas</FormLabel>
                                <RadioGroup
                                    aria-labelledby="grouped-by-time-hour"
                                    row
                                    name="groupedBy"
                                    value={timeByHour}
                                    onChange={handleTimeHourChange}
                                >
                                    <FormControlLabel value={5} control={<Radio/>} label="5"/>
                                    <FormControlLabel value={10} control={<Radio/>} label="10"/>
                                    <FormControlLabel value={15} control={<Radio/>} label="15"/>
                                    <FormControlLabel value={30} control={<Radio/>} label="30"/>
                                    <FormControlLabel value={60} control={<Radio/>} label="60"/>
                                </RadioGroup>
                            </FormControl>
                            : null
                        }
                    </Box>

                <br/>
                <Box
                    component="fieldset"
                    sx={{
                        borderRadius: 2
                    }}
                >
                    <legend>Eixo-X</legend>
                    <FormControl variant="standard" sx={{display: "inline", minWidth: 120 }}>
                        <Typography sx={{display: "inline"}}>Pontos: </Typography>
                        <TextField
                            style={{width: 40}}
                            type={"number"}
                            value={displayDataPoints}
                            variant={"standard"}
                            onChange={handleDisplayDataPoints}
                        />
                    </FormControl>
                </Box>
                <br/>
                <Box
                    component="fieldset"
                    sx={{
                        borderRadius: 2
                    }}
                >
                    <legend>Indicadores</legend>
                    {!isValidateYAxis &&
                        <FormHelperText id="component-error-text-start" style={{color: 'red'}}>
                            Cada eixo-y deve possuir uma unidade.
                        </FormHelperText>
                    }
                    { yAxisList?.map((yAxis, index) => {
                        return(
                            <>
                                <br/>
                                <ChartConfigDataItem
                                    key={yAxis.tag}
                                    yAxisTag={yAxis.tag}
                                    group={groupedByPeriod}
                                    yAxis={yAxis}
                                    xAxis={options?.chartConfig ? options?.chartConfig?.xAxisList[0] : null}
                                    series={seriesList.find(series => series.tag === yAxis.tag) ?? null}
                                    chartConfigType={getMapConfigType(options.chartConfig.configType).ordinal}
                                    isMultAxis={yAxisList.length > 1}
                                    onDisabled={yAxisList.length < 2 }
                                    onPreview={handleAxis}
                                    onDeleteAxis={handleDeleteAxis}
                                />
                                <br/>
                                <Divider />
                            </>
                        )
                        })
                    }
                </Box>
                <br/>
                <Grid container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                >
                    <Grid item xs={12} textAlign={"end"}>
                        <Button
                            size={"small"} style={{marginRight: 5}} variant="contained" color={"primary"}
                            endIcon={<EditIcon />}
                            onClick={() => onEdit(options.index, options.chartConfig, findSelectedIds(), options.filters)}
                            // disabled={nameChartConfig.length < 1}
                            disabled={true}
                        >
                            Editar
                        </Button>
                        <Button size={"small"} style={{marginRight: 5}} variant="contained" color={"info"} endIcon={<DeleteIcon />} onClick={() => onDeleteChartConfig(options.index)} disabled={!isAuthorizedToDelete}>Excluir</Button>
                        <Button size={"small"} variant="contained" color={"primary"} endIcon={<ReplayIcon />} onClick={handlePreview} disabled={nameChartConfig.length < 1 || (!!(endDateTime && startDateTime && isBefore(endDateTime, startDateTime)) && chartConfigPeriod === 'specific') || !isValidateYAxis }>Preview</Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        </Grid>
    );

}