import React, {FunctionComponent, useEffect, useState} from "react";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {EquipmentEntity, getEntities, reset} from "./ActivePowerInvertersSkidChartReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {FormControl, FormControlLabel, Radio, RadioGroup, useTheme} from "@mui/material";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {addMinutes, differenceInDays, format, parseISO} from "date-fns";
import {useSnackbar} from "notistack";
import {
    ACard,
    AChartX,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis,
    ACardSplitFooterActions
} from "@atiautomacao/ati-ui-library"
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";

export interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    yAxisIndex?: number;
    showSymbol?: boolean;
    data: any[];
    date?: any;
}

const GenerationActivePowerChart: FunctionComponent<{skidId: any}> = ({skidId}) =>  {

    const dispatch = useAppDispatch();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const { enqueueSnackbar } = useSnackbar();

    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const theme = useTheme();
    const equipmentEntity: Array<EquipmentEntity> = useAppSelector((state: RootState) => state.activePowerInvertersSkid.entities);
    const { loading, errorMessage } = useAppSelector((state: RootState) => state.activePowerInvertersSkid);
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(Array<YAxis>);
    const [series, setSeries] = useState<Array<SeriesOption>>(Array<SeriesOption>);

    const [radioGroup, setRadioGroup] = React.useState('activePower');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioGroup((event.target as HTMLInputElement).value);
    };

    const max = () => {
        const maxValues = Math.max(...equipmentEntity.map(element => element.activePower));
        return truncateNumber(maxValues + (maxValues * 0.15));
    }

    const hasVerifyRadio = () => {
        return radioGroup.includes("powerPerUnit");
    }

    const isWeek = () => {
        return period && period.groupBy.valueOf() === "week" ? '{dd}/{MM}' : '{HH}:{mm}'
    }

    const isDay = ()=>{
        return period && period.groupBy.valueOf() === "day"
    }

    const callback = (params:any) => {
        let formatDate = period && period.groupBy.valueOf() === "week" ? 'dd/MM HH:mm' : 'HH:mm'
        let dateStr = format(new Date(params[0].axisValue), formatDate);

        let result = dateStr + '<br/>';
        let unit = radioGroup === "powerPerUnit" ? "PU" : "kW"
        params.forEach(function (item: any) {
            result += item.marker + " " + item.seriesName + "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>" + item.value[1] + " " + unit + "</strong>" + "<br/>";
        });

        return result;
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00
        const equipmentName = dataPoints[0].equipmentName
        const equipmentId = dataPoints[0].equipmentId

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    const handleAxisY = () => {
        setYAxis([])
        if(hasVerifyRadio()){
            const maxPU = parseFloat("1.5");
            setYAxis([
                {
                    name: 'PU',
                    max: maxPU,
                    min: 0,
                    interval: truncateNumber(maxPU / 4, 2),
                    nameLocation: 'middle',
                    nameGap: 50,
                }
            ])
        } else {
            const maxAxisY = max();
            setYAxis([
                {
                    name: 'kW',
                    max: maxAxisY,
                    min: 0,
                    interval: truncateNumber(maxAxisY / 4, 2),
                    nameLocation: 'middle',
                    nameGap: 50,
                },
            ]);
        }
    }

    const handleSeriesData = (data:EquipmentEntity[]) => {
        const newData = isDay() ? adjustDataPoints(data) : data
        if(hasVerifyRadio()){
            return newData.map(element => element.powerPerUnit != null ? [element.dateTime, truncateNumber(element.powerPerUnit, 2)] : [element.dateTime, '-']);
        }
        return newData.map(element => element.activePower != null ? [element.dateTime, truncateNumber(element.activePower, 2)] : [element.dateTime, '-']);
    }

    const xAxisMin = (minDate:Date) : string | undefined => {
        if(!isDay()) return undefined;
        minDate.setHours(4, 0, 0, 0); // 4:00
        return format(minDate, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const xAxisMax = (maxDate:Date) : string | undefined => {
        if(!isDay()) return undefined;
        maxDate.setHours(20, 0, 0, 0);
        return format(maxDate, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const hoursToMilliseconds = (hours:number): number => {
        return hours * 60 * 60 * 1000
    };

    const findPowerActiveDataByPeriod = () => {
        handleAxisY();

        let maxDate = equipmentEntity.map(item =>  new Date(item.dateTime)).reduce((a,b) => a > b ? a : b)
        let minDate = equipmentEntity.map(item => new Date(item.dateTime)).reduce((a,b) => a < b ? a : b)

        let xAxisName = period && period.groupBy.valueOf() === "week" ? 'Dia' : 'Horário';

        setXAxis({
            name: xAxisName,
            type: 'time',
            min: xAxisMin(minDate),
            max: xAxisMax(maxDate),
            minInterval: period.groupBy.valueOf() === "day" ? hoursToMilliseconds(3) : hoursToMilliseconds(24),
            axisLabel: {
                formatter: isWeek(),
            },
            nameGap: 20,
            nameLocation: 'middle'
        })

        const equipmentEntitySort = [...equipmentEntity].sort((a, b) => {
            return a.equipmentName.localeCompare(b.equipmentName);
        });

        let dataSeries: Array<SeriesOption> = [];
        const equipmentIds = Array.from(new Set(
            equipmentEntitySort.map(
                item => item.equipmentId
            )
        ));
        equipmentIds.forEach(element => {
            let filter = equipmentEntitySort.filter(data => data.equipmentId === element);
            dataSeries.push(
                {
                    name: filter[0].equipmentName,
                    data: handleSeriesData(filter),
                    type: 'line',
                    showSymbol: false,
                    unity: hasVerifyRadio() ? 'PU' : 'kW',
                },
            )
        })
        setSeries(dataSeries)
    }

    useEffect(() => {
        dispatch(reset())
    }, [skidId]);

    useEffect(() => {
        if( period.toDateTime && period.fromDateTime && differenceInDays(period.toDateTime, period.fromDateTime) <= 6){
            dispatch(getEntities({period: period, skidId: skidId}));
        }else {
            enqueueSnackbar("Escolha um intervalo de datas com até 7 dias", {variant: 'error'})
        }
    }, [period,skidId]);



    useInterval(() => {
        if( period.toDateTime && period.fromDateTime && differenceInDays(period.toDateTime, period.fromDateTime) <= 6){
            dispatch(getEntities({period: period, skidId: skidId}));
        }else {
            enqueueSnackbar("Escolha um intervalo de datas com até 7 dias", {variant: 'error'})
        }
    }, 60000) // 5 minutes

    useEffect(() => {
        if(Array.isArray(equipmentEntity) && equipmentEntity.length > 0) {
            findPowerActiveDataByPeriod()
        }else {
            setSeries([])
        }

    }, [equipmentEntity, radioGroup]);

    useEffect(() => {
        if(errorMessage){
            enqueueSnackbar(errorMessage, {variant: 'error'})
        }
    }, [errorMessage]);

    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                    formatter: function (params: any) {
                        const formatDate = period.groupBy.valueOf() === "week" ? "dd/MM" : "HH:mm"

                        if (params.axisDimension === 'x') {
                            return format(new Date(params.value), formatDate);
                        }
                        if (params.axisDimension === 'y') {
                            return truncateNumber(params.value,2);
                        }
                    }
                }
            },
            formatter: callback
        },
        toolbox: {
            right: '10%',
            top: -8,
            itemSize: 15,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {}
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        legend: {
            show: true,
            type:'scroll',
            orient: 'horizontal', // Alterado para horizontal
            left: 'center',        // Centraliza a legenda
            bottom: 10,            // Coloca a legenda na parte inferior
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: series.map(el => el.name)
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 56
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animation: false,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series,
    };


    return (
        <>
            <ACard
                key={`chart-skid-active-power-${openSubMenu}`}
                headerControlsPosition={"header"}
                styleProps={{
                    cardStyle: { height: 445 },
                    contentStyle: { marginTop: 15, textAlign: 'right', height: 304 }
                }}
                title="Potência de Inversores"
                headerControls={
                    <>
                        <PeriodSelector
                            styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                            mode="hybrid"
                            hideDatetime={false}
                            hideGroup={false}
                            inputFormat={period.groupBy === "day" ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                            periodInterval={{
                                day: {date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59"},
                                week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                            }}
                            disabled={loading}
                            period={period}
                            onChange={setPeriod}
                            groupByOptions={["day", "week"]}
                        />
                    </>
                }
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //         />
                //     </>
                // }
                footerActions={
                    <ACardSplitFooterActions
                        leftActions={
                            <FormControl>
                                <RadioGroup
                                    style={{marginLeft: 10}}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="controlled-radio-buttons-group"
                                    value={radioGroup}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="powerPerUnit" control={<Radio />} label="PU" />
                                    <FormControlLabel value="activePower" control={<Radio />} label="kW" />
                                </RadioGroup>
                            </FormControl>
                        }
                        rightActions={
                            <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                                <IconButton disabled={true}>
                                    <FontAwesomeIcon  icon={faFileCsv} fontSize={20}/>
                                </IconButton>
                            </APopoverIcon>
                        }/>
                }
            >

                {
                    loading ?
                        <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                        :
                        series.length > 0 && xAxis && yAxis.length > 0 ?
                            <AChartX
                                height={289}
                                width={"100%"}
                                option={option}
                                theme={theme.palette.mode}
                            />
                            :
                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                }
            </ACard>
        </>
    );
}
export default GenerationActivePowerChart;