import {AppBar, Box, Button, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {Link, useNavigate} from "react-router-dom";
import {DeleteUsersReactQuery, UsersReactQuery} from "./UsersReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {QueryClient, QueryClientProvider} from "react-query";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import Toolbar from "@mui/material/Toolbar";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../Config/Constants";
import { useAppSelector } from "../../../Config/Hooks";
import { useSnackbar } from "notistack";

export const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    // {
    //     name: 'userName',
    //     label: 'Username',
    //     align: 'left',
    //     minWidth: 10,
    //     visibility: true,
    //     breakpoints: {
    //         xs: true,
    //         sm: true,
    //         md: true,
    //         lg: true,
    //         xl: true
    //     },
    // },
    {
        name: 'email',
        label: 'E-mail',
        align: 'right',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'groupList',
        label: 'Grupo',
        align: 'right',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
] as ADataGridColumn[]


export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowUsers {
    id?: number;
    name: string;
    username: string;
    email: string;
    groupList?: string;
    actionsConfig?: Array<ActionConfig>
}

export const UsersSettingsPage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSaveUser = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_USER
    ]);
    const isAuthorizedToDeleteUser = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_USER
    ]);
    
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const {data, isSuccess} = UsersReactQuery(pagedSearchParams);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowUsers>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);

    const navigate = useNavigate();
    const mutation = DeleteUsersReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if (data?.data) {
                let rowsAux: Array<RowUsers> = data.data.map((row: any) => {
                    return {
                        id: row.id,
                        name: row.name,
                        userName: row.userName,
                        email: row.email,
                        groupList: row.groupList,
                        actionsConfig: [
                            {actionName: "Editar", disabled: !isAuthorizedToSaveUser},
                            {actionName: "Excluir", disabled: !isAuthorizedToDeleteUser}
                        ]
                    }
                });
                setRows(rowsAux);
            }
        }
    }, [data?.data]);

    const handleEditUsers = useCallback((data: any) => {
        if(isAuthorizedToSaveUser) {
            navigate(SystemRoutes.SETTINGS_USERS_FORM, {state: {id: data.id}});
        } else {
            enqueueSnackbar("Você não está autorizado à acessar essa página", {
                variant: 'warning',
            });
            return null;
        }
    }, [navigate]);

    const handleDeleteUsers = useCallback((data: any) => {
        if(isAuthorizedToDeleteUser) {
            setSelectedToDelete(data.id);
            setOpenDialog(true);
        } else {
            enqueueSnackbar("Você não está autorizado à acessar essa página", {
                variant: 'warning',
            });
            return null;
        }
        
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate({id: selectedToDelete, pagedSearchParams});
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const theme = useTheme();

    return (
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <div style={{marginLeft: '22%'}}>
                        <Button
                            size={"small"}
                            variant="contained"
                            component={Link}
                            disabled={!isAuthorizedToSaveUser}
                            to={SystemRoutes.SETTINGS_USERS_FORM}
                            endIcon={<AddCircle/>}
                        >
                            Novo
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>
                <ACard>
                    <ADataGrid
                        columns={initColumns}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            onClick={(value) => handleEditUsers(value)} disabled={!isAuthorizedToSaveUser}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            onClick={(value) => handleDeleteUsers(value)} disabled={!isAuthorizedToDeleteUser}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar esse Usuário?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>
        </>
    );
}

export default function UsersHomePage() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <UsersSettingsPage/>
        </QueryClientProvider>
    )
}
