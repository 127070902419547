import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {useSnackbar} from "notistack";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

const apiUrl = "api/generation-inverter";

const fetchGenerationInverterByDaily = async (startDateTime: any, endDateTime: any, groupBy: any, powerStationId: number, equipmentId: number) => {
    try {
        return await axios.get(`${apiUrl}/${groupBy}`,
            {
                params: {
                    startDateTime: LocalDateTimeFormatISO(startDateTime),
                    endDateTime: LocalDateTimeFormatISO(endDateTime),
                    powerStationId: powerStationId,
                    equipmentId: equipmentId
                }
            }).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindGenerationInverterByDailReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: ({startDateTime, endDateTime, groupBy,  powerStationId, equipmentId}:any) => {
            return fetchGenerationInverterByDaily(startDateTime, endDateTime, groupBy,  powerStationId, equipmentId);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchTeleObjectInverter = async (equipmentId: number, powerStationId: number) => {
    try {
        return await axios.get(`api/teleobject/inverter-object-type/${equipmentId}/${powerStationId}`)
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindTeleObjectInverterReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: ({equipmentId, powerStationId}:any) => {
            return fetchTeleObjectInverter(equipmentId, powerStationId);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados de inversor: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchPowerVSIrradianceDataByEquipmentIdAndByHour = async (
    powerStationId: number,
    equipmentId: number,
    startDateTime: any,
    endDateTime: any) => {

    const formattedStartDateTime = LocalDateTimeFormatISO(startDateTime);
    const formattedEndDateTime = LocalDateTimeFormatISO(endDateTime);


    const { data } = await axios.get(`/api/power-irradiance/hour/inverters`, {
        params: {
            powerStationId,
            equipmentId,
            startDateTime: formattedStartDateTime,
            endDateTime: formattedEndDateTime
        }
    });

    return data;
};

export const FindPowerVSIrradianceDataByEquipmentIdAndByHour = (
    powerStationId: number,
    equipmentId: number,
    startDateTime: any,
    endDateTime: any
) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery(
        ['powerIrradiance', powerStationId, equipmentId, startDateTime, endDateTime],
        async () => {
            return fetchPowerVSIrradianceDataByEquipmentIdAndByHour(
                powerStationId,
                equipmentId,
                startDateTime,
                endDateTime
            );
        },
        {
            enabled: !!powerStationId && !!equipmentId && !!startDateTime && !!endDateTime, // Executa apenas se todos os parâmetros estiverem definidos
            onError: (error: any) => {
                enqueueSnackbar(
                    `Erro ao carregar dados de potência vs irradiância: ${error.message}`,
                    { variant: 'error' }
                );
            },
        }
    );
};


